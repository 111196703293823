import React from "react";
import Home from "./pages/home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Privacy from "./pages/privacy";
import PrivacyPoint from "./pages/privacy-point";
import DeleteRequest from "./pages/delete-request";
import CaPrivacy from "./pages/ca-privacy";
import CaPrivacyPoint from "./pages/ca-privacy-point";
import FallingPlanePrivacy from "./pages/falling-plane-privacy";
import "./i18n";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" index element={<Home />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/ca-privacy" element={<CaPrivacy />} />
        <Route path="/privacy-point" element={<PrivacyPoint />} />
        <Route path="/ca-privacy-point" element={<CaPrivacyPoint />} />
        <Route path="/delete-request" element={<DeleteRequest />} />
        <Route
          path="/falling-plane-privacy"
          element={<FallingPlanePrivacy />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
